<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div class='no_draw_times zc_dialog_inv'>
            <el-dialog :visible.sync="dialogVisible" width="550px" :close-on-click-modal='false'>
                <div class="draw_main">
                    <div class="title">{{ title }}</div>
                    <div class="tip">开通立享6大会员权益</div>
                    <div class="draw_list">
                        <div class="list" v-for="(item, index) in draw_list" :key="index">
                            <img :src="item.image" alt="">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div class="draw_btn">
                        <el-button class="btnBgColor_blue" type="primary" @click="interestsBtn">立即开通会员</el-button>
                        <el-button class="btnBgColor_blue_empty_no" @click="investBtn(type)">去购买次数包</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <open-member ref='dialogMember'></open-member>
        <invest-money ref="InvestMoney" @renovate='renovate' />

    </div>
</template>

<script>
import OpenMember from '@/components/invest_money/open_member.vue' // 弹框==开通会员
import InvestMoney from '@/components/invest_money/invest_money.vue'//充值

import {
    mixinIndex
} from "@/mixin/index"
export default {
    mixins: [mixinIndex],
    components: { OpenMember, InvestMoney, },
    props: {
        title: {
            type: String,
            default: '您还没有次数'
        },
        type: {
            type: Number,
            default: 2
        },

    },
    data() { 
        return {
            dialogVisible: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        open() {
            this.dialogVisible = true
        },
        // 更多权益
        interestsBtn() {
            this.$refs.dialogMember.openDialogBtn()
        },
        // 充值
        investBtn(type) {
            this.$refs.InvestMoney.openDialogBtn(type)
        },
        renovate() {
            this.$emit('renovate')
        }
    },
}
</script>

<style lang='scss' scoped>
.no_draw_times {
    ::v-deep .el-dialog {
        border-radius: 20px;
        background: none;

        .el-dialog__close {
            color: #333;
        }
    }

    .draw_main {
        width: 100%;
        height: 370px;
        border-radius: 20px 20px;
        background: url(~@/assets/images/background/draw_background.png) no-repeat;
        background-size: 100% 100%;

        .title {
            padding-top: 24px;
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .tip {
            padding-top: 6px;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }

        .draw_list {
            width: 100%;
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;

            .list {
                box-sizing: border-box;
                width: 50%;
                padding-top: 20px;
                padding-left: 96px;
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                span {
                    padding-left: 10px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 40px;
                }
            }

            .list:nth-child(2n) {
                padding-left: 45px;
            }
        }

        .draw_btn {
            box-sizing: border-box;
            width: 100%;
            padding: 34px 130px 0;
            display: flex;
            justify-content: space-between;

            .el-button {
                padding: 11px 20px;
            }
        }
    }
}
</style>
